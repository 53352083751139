<template>
  <!-- 线路绑定 -->
  <div ref="bindLine" class="bindLine">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">用户姓名：</div>
        <div class="col-2">
          <el-input v-model="form.userName"></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">用户名：</div>
        <div class="col-2">
          <el-input v-model="form.loginName"></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">所属公司：</div>
        <div class="col-2">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">联系方式：</div>
        <div class="col-2">
          <el-input v-model="form.phone"></el-input>
        </div>
      </div>
      <!-- <div class="actionBar-box">
        <div class="col-1">绑定渠道：</div>
        <div class="col-2">
          <el-select
            v-model="form.bindChannelNos"
            multiple
            collapse-tags
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in channelList"
              :key="item.channelNo"
              :label="item.channelName"
              :value="item.channelNo"
            ></el-option>
          </el-select>
        </div>
      </div> -->
      <div class="actionBar-box">
        <div class="col-1">绑定线路：</div>
        <div class="col-2">
          <el-select
            v-model="form.bindLineIds"
            multiple
            filterable
            collapse-tags
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in bindListArry"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="query">查询</el-button>
        <el-button
          v-if="listFind('新增')"
          type="primary"
          size="small"
          @click="addNewLine"
          >添加</el-button
        >
      </div>
    </div>
    <Table
      :table-height="tableHeight"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              v-if="listFind('编辑')"
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow)"
              >编辑</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('删除')"
              type="text"
              size="small"
              sort="danger"
              @click="deleteBtn(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getAllLine,
  getCompanyTree,
  scheduleLineAssociationTableDataAPI,
  deleteLineInfoAPI,
  queryChannelListAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      total: 0,
      tableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: "用户名",
          props: "loginName",
        },
        {
          title: "用户姓名",
          props: "userName",
        },
        {
          title: "联系方式",
          props: "phone",
        },
        {
          title: "所属公司",
          props: "companyNames",
          SpecialJudgment: (res) => {
            return res.join("、");
          },
        },
        // {
        //   title: "绑定渠道",
        //   props: "bindChannelNames",
        //   width: 200,
        //   SpecialJudgment: (res) => {
        //     if (res) {
        //       let text = "";
        //       res.map((item) => {
        //         text += item + "、";
        //       });
        //       return text.substr(0, text.length - 1);
        //     } else {
        //       return res;
        //     }
        //   },
        // },
        {
          title: "绑定线路",
          props: "bindLineNames",
          width: 350,
          SpecialJudgment: (res) => {
            let text = "";
            res.map((item) => {
              text += item + "、";
            });
            return text.substr(0, text.length - 1);
          },
        },
      ],
      bindListArry: [],
      channelList: [],
      companyList: [],
      value: null,
      valuename: null,
      form: {
        userName: "", //用户姓名
        loginName: "", //用户名
        phone: "", //联系方式
        bindLineIds: [], //绑定线路id
        // bindChannelNos: [],
        companyId: "", //公司id
      },
      currentPage: 1, //页数
      pageSize: 10, //分页大小
      isClearBindChannelNos: true,
    };
  },
  created() {
    this.getCompanyList();
    // 获取查询条件下的绑定线路数据
    getAllLine().then((res) => {
      if (res.code == "SUCCESS") {
        this.bindListArry = res.data;
      }
    });
  },
  mounted() {
    this.renderData();
    this.getQueryChannelList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      queryChannelListAPI({ status: 0, companyId: null }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
        }
      });
    },
    // 获取表格数据
    renderData() {
      scheduleLineAssociationTableDataAPI({
        userName: this.form.userName, //用户姓名
        loginName: this.form.loginName, //用户名
        phone: this.form.phone, //联系方式
        companyId: this.form.companyId, //公司ID
        bindLineIds: this.form.bindLineIds, //绑定线路ID,以逗号分割
        // bindChannelNos: this.form.bindChannelNos, //绑定线路ID,以逗号分割
        currentPage: this.currentPage, //页数
        pageSize: this.pageSize, //分页大小
      }).then((res) => {
        if (this.form.bindLineIds.length) {
          const list = res.data.list;
          this.tableData = list.filter((item) => {
            const str = item.bindLineIds.toString();
            const arr = this.form.bindLineIds;
            for (let i = 0; i < arr.length; i++) {
              if (str.indexOf(arr[i]) == -1) return false;
            }
            return true;
          });
          this.total = this.tableData.length;
        } else {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.bindLine.clientHeight;
      const ActionBarHeight = this.$refs.ActionBar.clientHeight;
      this.tableHeight = wholeHeight - ActionBarHeight - 100;
    },
    // 编辑按钮
    updateBtn(obj) {
      this.$router.push({
        path: "/projectTravel/bindLine/addBindLine",
        query: { id: obj.id },
      });
    },
    // 删除按钮
    deleteBtn(obj) {
      this.$confirm("确认删除吗？", "提示", {
        type: "warning",
      }).then(() => {
        deleteLineInfoAPI(obj.id).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("删除成功");
            this.currentPage = 1;
            this.renderData();
          }
        });
      });
    },
    // 分页大小change
    onSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.renderData();
    },
    // 分页页数改变change
    onCurrentChange(val) {
      this.currentPage = val;
      this.renderData();
    },
    // 查询线路
    query() {
      this.currentPage = 1;
      this.renderData(this.form);
    },
    // 添加新线路
    addNewLine() {
      this.$router.push("/projectTravel/bindLine/addBindLine");
    },
    // 企业id回显
    getGroupId(v) {
      this.form.companyId = v;
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.bindLine {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 32px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .el-select {
    z-index: 1;
  }
}
</style>
